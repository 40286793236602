<template>
    <picture>
        <source type="image/webp" :srcset="require(`@/assets/img/${newImageName}.webp`)" />
        <img
            :src="require(`@/assets/img/${newImageName}.${newImageFormat}`)"
            :alt="alternativeInfo"
            :class="className"
        />
    </picture>
</template>

<script>
export default {
    name: "ZPicture",
    data() {
        return {
            imageFormat: "png"
        };
    },
    props: {
        img: {
            type: String,
            require: true,
            default: "img-zf-01"
        },
        alt: {
            type: String,
            default: ""
        },
        className: {
            type: String,
            default: ""
        }
    },

    computed: {
        alternativeInfo() {
            return this.alt === "" ? this.img : this.alt;
        },
        newImageFormat() {
            const imageInfo = this.img.split(".");
            const imageFormat = imageInfo.length > 1 ? imageInfo[1] : this.imageFormat;
            return imageFormat;
        },
        newImageName() {
            const imageInfo = this.img.split(".");
            const imageName = imageInfo.length > 1 ? imageInfo[0] : this.img;
            return imageName;
        }
    }
};
</script>

<style lang="scss" scoped>
.fulfillment-service {
    height: 52px;
    width: 52px;
    margin-bottom: 8px;
}

.zone-coverage {
    img {
        width: 100%;
        max-width: 440px;
        border-radius: 30px;
    }
}
</style>
